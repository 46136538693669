/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import axios from 'axios';
import { KTIcon } from '../../../helpers'

import { CreateChannelModal } from '../../modals/create-channel/CreateChannelModal';

import { ConfirmDeletionModal } from '../../modals/confirm-deletion/ConfirmDeletionModal';

import { Channel, ChannelIcon, ChannelIconColor, ChannelSatusText, ChannelStatusColor } from '../../../../models/Channel';

type Props = {
  className: string
}

function formatPhone(phone: string) {

  const country = phone.substring(0, 2);  // Including the + sign
  const region = phone.substring(2, 4);
  const firstPart = phone.substring(4, 8);
  const secondPart = phone.substring(8);

  return `+${country} ${region} ${firstPart}-${secondPart}`;
}
const ChannelsList: React.FC<Props> = ({ className }) => {

  const [showCreateChannelModal, setShowCreateChannelModal] = React.useState<boolean>(false);
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = React.useState<{[key : string] : boolean}>({});
  const [channels, setChannels] = React.useState([] as Channel[]);
  const [editedProperties, setEditedProperties] = React.useState({ name: {} } as {
    name: {
      [key: string]: string
    }
  });

  const [editingRow, setEditingRow] = React.useState({
    name: -1
  });

  const fetchChannels = () => {
    axios.get('/api/channel').then(response => {
      if (response?.data?.length) setChannels(response.data);
    })
  }


  React.useEffect(() => {
      fetchChannels();
  }, []);

  return (
    <div  className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 id = "tour-step-0" className='card-title fw-bold text-dark'>Canais de Notificação</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a  
              onClick={() => setShowCreateChannelModal(true)}
              className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
            >
              
              <KTIcon iconName='plus' className='fs-3' />
              Novo Canal

            </a>
          </div>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {channels.map((channel, index) => (
          <div className='d-flex align-items-center mb-8'>
            {/* begin::Bullet */}
            <span className={`bullet bullet-vertical h-40px bg-${ChannelIconColor[channel.type]}`}></span>
            {/* end::Bullet */}
            <span className='symbol symbol-40px me-6'>
              <span className={`symbol-label bg-light-${ChannelIconColor[channel.type]}`}>
                <KTIcon iconName={ChannelIcon[channel.type]} className={`fs-1 text-${ChannelIconColor[channel.type]}`} />
              </span>
            </span>
            {/* begin::Description */}
            <div className='flex-grow-1'>

              {editingRow.name === index ? <div className='align-items-center input-group input-group-lg input-group-solid mb-4'>
                <input
                  type='text'
                  className='fs-7 form-control form-control-lg form-control-solid bg-blue'
                  value={editedProperties.name[channel.id] || channel.name}
                  onChange={(e) => setEditedProperties({
                    ...editedProperties,
                    name: {
                      ...editedProperties.name,
                      [channel.id]: e.target.value
                    }
                  })}

                />

                <a className='fs-10 btn btn-icon' onClick={() => {
                  setEditedProperties({
                    ...editedProperties,
                    name: {
                      ...editedProperties.name,
                      [channel.id]: channel.name
                    }
                  })
                  setEditingRow({
                    ...editingRow,
                    name: -1
                  })
                }}
                >
                  <KTIcon iconName='cross-square' className='fs-1 text-danger' />

                </a>

                <a
                  className='fs-10 btn btn-icon'

                  onClick={() => {

                    if (!editedProperties.name[channel.id] || editedProperties.name[channel.id] === channel.name) {
                      setEditingRow({
                        ...editingRow,
                        name: -1
                      });
                      return;
                    }

                    axios.put(`/api/channel/${channel.id}`, {
                      name: editedProperties.name[channel.id]
                    }).then(() => {
                      setChannels([
                        ...channels.slice(0, index),
                        {
                          ...channel,
                          name: editedProperties.name[channel.id]
                        } as Channel,
                        ...channels.slice(index + 1)
                      ]);
                      setEditingRow({
                        ...editingRow,
                        name: -1
                      });
                    }).catch((err) => {
                      console.error(err)
                    }
                    );
                  }}
                >
                  <KTIcon iconName='check' className='fs-1 text-primary' />
                </a>

              </div> :
                <div className='d-flex align-items-center'>
                  <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                    {channel.name}
                  </a>
                  <a
                    onClick={() => setEditingRow({
                      ...editingRow,
                      name: index
                    })}
                    className='btn btn-icon'
                  >
                    <KTIcon iconName='pencil' className='fs-7' />
                  </a>
                </div>}


              <span className='text-muted fw-semibold d-block'>{formatPhone(channel.info)}</span>
              <ConfirmDeletionModal objectType={`Canal de ${channel.type}`} objectName={`Canal de ${channel.type}: ${channel.name}`} show={showConfirmDeletionModal[channel.id]} handleClose={() => setShowConfirmDeletionModal({...showConfirmDeletionModal, [channel.id] : false})} submit={() => {
                axios.delete(`/api/channel/${channel.id}`).then(response => {
                  fetchChannels();
                });
              }} />
            </div>
            {/* end::Description */}
            <span className={`badge badge-light-${ChannelStatusColor[channel.status]} fs-8 fw-bold`}>{ChannelSatusText[channel.status]}</span>

            <div className='d-flex justify-content-end flex-shrink-0 ms-2'>
              <a
                onClick={() => {
                  setShowConfirmDeletionModal({
                    ...showConfirmDeletionModal,
                    [channel.id] : true
                  });
                }}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              >
                <KTIcon iconName='trash' className='fs-4' />
              </a>
            </div>
          </div>
        ))}
      </div>
      {/* end::Body */}
      <CreateChannelModal show={showCreateChannelModal} handleClose={() => { 
        setShowCreateChannelModal(false);
        fetchChannels();
        }} />


    </div>

  )
}

export { ChannelsList }
