import axios from 'axios'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit, useAuth } from './modules/auth'
import { setupAxios } from './modules/auth'

import ErrorBoundary from '../ErrorBoundary'
import * as Frigade from '@frigade/react'

const App = () => {

  const { currentAccount, currentUser } = useAuth();

  console.log(currentUser)

  setupAxios(axios, currentAccount)
  return (
    // <ErrorBoundary>

    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Frigade.Provider
              apiKey="api_public_cLcYIWxewJx0tYDp0gCtIUEDRzifoLjue1MQ6BLmFW4Dk3u56KgbxLy9X9q8H0Lo"
              userId={currentUser?.id.toString()}
              userProperties={{
                firstName : currentUser?.name,
                email : currentUser?.email,
              }}
            >
              <Outlet />
              <MasterInit />
            </Frigade.Provider>

          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
    // </ErrorBoundary>
  )
}

export { App }
