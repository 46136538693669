/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useState, useEffect } from 'react'

import { CreateTrackingModal } from '../../modals/create-tracking-stepper/CreateTrackingModal'
import { toAbsoluteUrl } from '../../../helpers'
import axios from 'axios'
import { Channel } from '../../../../models/Channel'

type Props = {
  className?: string
  bgColor?: string
  title?: string
  cta?: string
}
const TilesWidget2 = ({
  className,
  bgColor = '#663259',
  title = 'Create SaaS',
  cta = 'Create Campaign'
}: Props) => {

  const [showCreateTrackingModal, setShowCreateTrackingModal] = useState<boolean>(false)

  const [channels, setChannels] = useState([] as Channel[]);

  useEffect(() => {
    axios.get('/api/channel').then(response => {
      if (response?.data?.length) setChannels(response.data);
    });
  }, []);

  return (
    <div
      className={clsx('card h-150px bgi-no-repeat bgi-size-contain', className)}
      style={{
        backgroundColor: bgColor,
        backgroundPosition: 'right',
        backgroundImage: `url("${toAbsoluteUrl('/media/svg/misc/taieri.svg')}")`,
      }}
    >
      <div className='card-body d-flex flex-column justify-content-between'>
        <h2 className='text-white fw-bold mb-5'>
          {title}
        </h2>

        <div className='m-0'>
          <a
            className='btn btn-primary text-primary text-dark fw-semibold px-6 py-3'
            onClick={() => {

              if (channels?.length > 0) {
                setShowCreateTrackingModal(true)

              } else {
                alert('Você precisa ter pelo menos 1 canal de notificação criado para criar um monitoramento. Utilize o botão "+ Novo Canal" para criar um canal de notificação.')
              }

            }}

          >
            {cta}
          </a>
        </div>
      </div>

      <CreateTrackingModal show={showCreateTrackingModal} handleClose={() => setShowCreateTrackingModal(false)} />
    </div>
  )
}

export { TilesWidget2 }
