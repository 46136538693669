/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { StepProps } from '../../../../../models/Tracking'
import axios from 'axios';
import { IProcessBasic } from '../../../../../models/Process';
import { Card2 } from '../../../content/cards/Card2';
import { MultiSelectDropdownWithInput } from '../../../../../components/inputs/MultiSelectDropdownWithInput';
import journals from './journals.json';
import { Card } from 'react-bootstrap';
import { MultiSelectDropdown } from '../../../../../components/inputs/MultiSelectDropdown';
import courts from './courts.json'

const typeToIdMap = {
  'process_number': 'Número do Processo',
  'cpf': 'CPF',
  'cnpj': 'CNPJ',
  'oab': 'OAB',
  'keyword': 'Palavra-chave'
}

const states: { [key: string]: string } = {
  'AC': 'Acre',
  'AL': 'Alagoas',
  'AP': 'Amapá',
  'AM': 'Amazonas',
  'BA': 'Bahia',
  'CE': 'Ceará',
  'DF': 'Distrito Federal',
  'ES': 'Espírito Santo',
  'GO': 'Goiás',
  'MA': 'Maranhão',
  'MT': 'Mato Grosso',
  'MS': 'Mato Grosso do Sul',
  'MG': 'Minas Gerais',
  'PA': 'Pará',
  'PB': 'Paraíba',
  'PR': 'Paraná',
  'PE': 'Pernambuco',
  'PI': 'Piauí',
  'RJ': 'Rio de Janeiro',
  'RN': 'Rio Grande do Norte',
  'RS': 'Rio Grande do Sul',
  'RO': 'Rondônia',
  'RR': 'Roraima',
  'SC': 'Santa Catarina',
  'SP': 'São Paulo',
  'SE': 'Sergipe',
  'TO': 'Tocantins'
}


const Step2 = (args: StepProps) => {

  const type = args.data.type;

  return (
    <div className='pb-5' data-kt-stepper-element='content'>
      {type === 'process_number' ? <ProcessInput {...args} /> :
        type === 'keyword' ? <KeywordInput {...args} /> :
        type === 'oab' || type === 'cpf' || type === 'cnpj' ? <DocumentInput {...args} /> :
          null}
    </div>
  );
}


const ProcessInput = ({ data, updateData, hasError }: StepProps) => {

  const [process, setProcess] = useState<IProcessBasic | false>();
  const [isSearching, setIsSearching] = useState(false);

  const findProcess = () => {

    setIsSearching(true);

    axios.get(`/api/process?number=${data.identifier}`).then((res) => {

      if (res.data) {
        updateData({ ...data, process: res.data });
        setProcess(res.data);
      } else {
        updateData({ ...data, process: undefined })
        setProcess(false)
      }

    }).catch((err) => {
      updateData({ ...data, process: undefined })
      setProcess(false)
    }).finally(() => setIsSearching(false));

  }

  return <div className='w-100'>
    {/*begin::Form Group */}
    <form>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>{typeToIdMap[data.type]}</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Especifique o identificador do monitoramento'
          ></i>

        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='appname'
          placeholder=''
          value={data.identifier}
          onChange={(e) =>
            updateData({
              identifier: e.target.value
            })
          }
        />

        {!data.identifier && hasError && (
          <div className='fv-plugins-message-container'>
            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
              É obrigatório inserir um identificador
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Estado</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Selecione o estado'
          ></i>
        </label>
        <div className='card-toolbar'>
          <div className='col-6'>
            <select
              name='date_typer'
              aria-label='Select a state'
              data-control='select'
              placeholder='Selecione o estado'
              className='form-select form-select-sm form-select-solid fs-5'
              value={data.state}
              onChange={(e) =>
                updateData({
                  state: e.target.value
                })
              }

            >
              <option value={undefined}>Selecione o estado</option>
              {Object.keys(states).map((key) => (<option value={key}>{states[key]}</option>))}
            </select>
          </div>
        </div>
      </div>
      <button style={{ marginBottom: 20 }}
        type='button'
        className='btn btn-sm btn-secondary me-3'
        data-kt-stepper-action='previous'
        onClick={findProcess}
        disabled={isSearching}
      >
        Procurar
        {isSearching && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
      </button>
    </form>
    <div className='row g-9 g-xl-12'>
      {process ? <Card2
        icon='/media/svg/brand-logos/plurk.svg'
        badgeColor='primary'
        status='In Progress'
        statusColor='primary'
        title={data.name as string}
        description={process.number}
        date='November 10, 2021'
        journal_name={process.journal_name}
        progress={50}
      /> : process === false ? <div className='alert alert-danger' role='alert'>Processo não encontrado </div> : <></>}
    </div>
    {!data.process && hasError && (
      <div className='fv-plugins-message-container'>
        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
          É obrigatório inserir um processo válido. Insira o número do processo e clique em proacurar para encontrar o processo
        </div>
      </div>
    )}
  </div>
}

interface SelectInput {
  value:  string | number ;
  label: string;
}

const journalOptions = journals.map(({ nome, diarios }) => (
  {
    label: nome, options: diarios.sort((a, b) => a.nome.localeCompare(b.nome)).map((journal) => (
      {label : journal.nome, value: journal.id}
    ))
  }
));

const KeywordInput = ({ data, updateData, hasError }: StepProps) => {

  return <div className='w-100'>
    {/*begin::Form Group */}
    <form>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Diário</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Selecione o Diário Oficial que deseja monitorar'
          ></i>
        </label>
        <div className='card-toolbar'>
          <div className='col-12'>
            <MultiSelectDropdown
              id='journals'
              label='Diários'
              options={journalOptions}
              value = {data.journal_ids?.length ? journalOptions.flatMap((option) => option.options).filter((option) => data.journal_ids?.includes(option.value)) : []}
              setValue = {(values: SelectInput[]) => {
                updateData({
                  journal_ids: values.map((value) => value.value as number)
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>{typeToIdMap[data.type]}</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Especifique o identificador do monitoramento'
          ></i>

        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='appname'
          placeholder=''
          value={data.identifier}
          onChange={(e) =>
            updateData({
              identifier: e.target.value
            })
          }
        />

        {!data.identifier && hasError && (
          <div className='fv-plugins-message-container'>
            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
              É obrigatório inserir um identificador
            </div>
          </div>
        )}
      </div>



      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold'>
          <span>Variações</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Selecione até 3 variações da palavra-chaves'
          ></i>
        </label>
        <div className='card-toolbar'>
          <div className='col-12'>
            <MultiSelectDropdownWithInput
              id='variations'
              maxSelection={3}
              value={data.aux_keywords.filter(value => value.condition === 'variations').map((value) => ({ value: value.keyword, label: value.keyword }))}
              setValue={(values: SelectInput[]) => {
                updateData({
                  aux_keywords: data.aux_keywords.filter(value => value.condition !== 'variations').concat(values.map((value) => ({ keyword: value.value as string, condition: 'variations' })))
                });
              }}
              options={[]}
            />
          </div>
        </div>
      </div>

      <Card className='border shadow-sm'>
        <Card.Header className='bg-light-primary'>
          <label className='d-flex text-primary align-items-center fs-4 fw-semibold'>
            <span>Filtros</span>
          </label>
          <span>Utilize os filtros abaixo para refinar seu monitoramento.</span>
        </Card.Header>

        <Card.Body className='fs-4 fw-semibold mb-0'>

          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold'>
              <span>CONTEM</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione até 3 termos que devem estar presentes no texto. Apenas iremos alertar se a página da publicação conter todos os nomes informado'
              ></i>
            </label>
            <div className='card-toolbar'>
              <div className='col-12'>
                <MultiSelectDropdownWithInput
                  id='contains'
                  maxSelection={3}
                  value={data.aux_keywords.filter(value => value.condition === 'contains').map((value) => ({ value: value.keyword, label: value.keyword }))}
                  setValue={(values: SelectInput[]) => {
                    updateData({
                      aux_keywords: data.aux_keywords.filter(value => value.condition !== 'contains').concat(values.map((value) => ({ keyword: value.value as string, condition: 'contains' })))
                    });
                  }}
                  options={[]}
                />
              </div>
            </div>
          </div>

          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold'>
              <span>CONTEM ALGUM</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Apenas iremos alertar, se a publicação tiver pelo menos 1 dos termos informados.'
              ></i>
            </label>
            <div className='card-toolbar'>
              <div className='col-12'>
                <MultiSelectDropdownWithInput
                  id='contains_some'
                  maxSelection={3}
                  value={data.aux_keywords.filter(value => value.condition === 'contains_some').map((value) => ({ value: value.keyword, label: value.keyword }))}
                  setValue={(values: SelectInput[]) => {
                    updateData({
                      aux_keywords: data.aux_keywords.filter(value => value.condition !== 'contains_some').concat(values.map((value) => ({ keyword: value.value as string, condition: 'contains_some' })))
                    });
                  }}
                  options={[]}
                />
              </div>
            </div>
          </div>


          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold'>
              <span>NÃO CONTEM</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Não iremos alertar se a publicação tiver pelo menos 1 dos termos informados.'
              ></i>
            </label>
            <div className='card-toolbar'>
              <div className='col-12'>
                <MultiSelectDropdownWithInput
                  id='not_contains'
                  maxSelection={3}
                  value={data.aux_keywords.filter(value => value.condition === 'not_contains').map((value) => ({ value: value.keyword, label: value.keyword }))}
                  setValue={(values: SelectInput[]) => {
                    updateData({
                      aux_keywords: data.aux_keywords.filter(value => value.condition !== 'not_contains').concat(values.map((value) => ({ keyword: value.value as string, condition: 'not_contains' })))
                    });
                  }}
                  options={[]}
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>



    </form>

  </div>
}

const DocumentInput = ({ data, updateData, hasError }: StepProps) => {

  return <div className='w-100'>
    {/*begin::Form Group */}
    <form>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Número do Documento {typeToIdMap[data.type]}</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Especifique o identificador do monitoramento'
          ></i>

        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='appname'
          placeholder=''
          value={data.identifier}
          onChange={(e) =>
            updateData({
              identifier: maskMap[data.type](e.target.value)
            })
          }
        />

        {!data.identifier && hasError && (
          <div className='fv-plugins-message-container'>
            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
              É obrigatório inserir o número do documento {typeToIdMap[data.type]}
            </div>
          </div>
        )}
        {data.identifier && hasError && (
          <div className='fv-plugins-message-container'>
            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
              Insira um {typeToIdMap[data.type]} válido
            </div>
          </div>
        )}
      </div>

      {data.type === 'oab' && <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Estado</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Selecione o estado'
          ></i>
        </label>
        <div className='card-toolbar'>
          <div className='col-6'>
            <select
              name='date_typer'
              aria-label='Select a state'
              data-control='select'
              placeholder='Selecione o estado'
              className='form-select form-select-sm form-select-solid fs-5'
              value={data.state}
              onChange={(e) =>
                updateData({
                  state: e.target.value
                })
              }

            >
              <option value={undefined}>Selecione o estado</option>
              {Object.keys(states).map((key) => (<option value={key}>{states[key]}</option>))}
            </select>
            {!data.state && hasError && (
          <div className='fv-plugins-message-container'>
            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
              É obrigatório selecionar o estado
            </div>
          </div>
        )}
          </div>
        </div>
      </div>}

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='required'>Tribunal</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Selecione o tribunal'
          ></i>
        </label>
        <div className='card-toolbar'>
          <div className='col-6'>
            <select
              name='date_typer'
              aria-label='Select a court'
              data-control='select'
              placeholder='Selecione o tribunal'
              className='form-select form-select-sm form-select-solid fs-5'
              value={data.court}
              onChange={(e) =>
                updateData({
                  court: e.target.value
                })
              }

            >
              <option value={undefined}>Selecione o tribunal</option>
              {courts.filter((court) => {

                switch (data.type){

                  case 'cpf':
                  case 'cnpj' :
                    return court.busca_documento === 1;
                  
                  case 'oab':
                    return court.busca_oab === 1;
                  
                  case 'process_number':
                    return court.busca_processo === 1;

                  default:
                    return false;

                }




              }).map((court) => (<option value={court.sigla}>{court.nome}</option>))}
            </select>
            {!data.court && hasError && (
          <div className='fv-plugins-message-container'>
            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
              É obrigatório selecionar o tribunal
            </div>
          </div>
        )}
          </div>
        </div>
      </div>
    </form>
  </div>
}

const maskMap = {
  'cpf': (value: string) => value
    .replace(/\D/g, '') 
    .replace(/(\d{3})(\d)/, '$1.$2') 
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') 
    ,
  'cnpj': (value : string) => value
    .replace(/\D/g, '') 
    .replace(/(\d{2})(\d)/, '$1.$2') 
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') 
    ,
  'oab': (value : string) => value.replace(/[^\d]+/g, '') 


  ,
  'process_number': (value : string) => value
  ,
  'keyword' : (value : string) => value
  }



export { Step2 }
